


























































import * as R from 'ramda';
import { useAxios } from '@vue-composable/axios';
import { defineComponent } from '@vue/composition-api';
import { SvgImage } from '@/app/components';
import QueryOverview from './QueryOverview.vue';
import { QueryAPI } from '../../api/index';

export default defineComponent({
    name: 'Queries',
    components: {
        QueryOverview,
        SvgImage,
    },
    props: {
        queries: {
            type: Array,
            required: true,
        },
    },
    setup(props, { root, emit }) {
        const { exec } = useAxios();

        const edit = (query: any) => {
            emit('editQuery', query);
        };
        const destroy = (queryId: string) => {
            const idx = R.findIndex(R.propEq('id', queryId), props.queries as any[]);
            exec(QueryAPI.delete(queryId))
                .then(() => {
                    (root as any).$toastr.s('Query deleted successfuly', 'Success');
                    props.queries.splice(idx, 1);
                    if (root.$route.params.id && root.$route.params.id === queryId) {
                        root.$router.push({ name: 'search' });
                    }
                })
                .catch(() => {
                    (root as any).$toastr.e('Deleting Query failed', 'Error');
                });
        };

        return { edit, destroy, emit };
    },
});
