
















































































import { defineComponent, watch, ref, computed } from '@vue/composition-api';
import * as R from 'ramda';
import { S } from '@/app/utilities';
import { QueryBuilder, Scrollbar } from '@/app/components';
import { QueryOperant } from '@/app/constants';
import ConditionEditor from './ConditionEditor.vue';
import ConditionViewer from './ConditionViewer.vue';
import DataQuerySummary from './DataQuerySummary.vue';

export default defineComponent({
    name: 'AdvancedQueryBuilder',
    components: { QueryBuilder, ConditionEditor, DataQuerySummary, ConditionViewer, Scrollbar },
    model: {
        prop: 'query',
        event: 'update-query',
    },
    props: {
        query: {
            type: Object,
            default: () => ({ conditions: [], operant: 'AND' }),
        },
        concepts: {
            type: Object,
            required: true,
        },
        visible: {
            type: Boolean,
            default: true,
        },
    },
    setup: (props: any, { emit }) => {
        const tempQuery = ref<any>(props.query);

        const hasChanges = computed(() => {
            if (
                (R.isNil(tempQuery.value) || R.isEmpty(tempQuery.value)) &&
                (R.isNil(props.query) || R.isEmpty(props.query))
            ) {
                return false;
            }
            return JSON.stringify(tempQuery.value) !== JSON.stringify(props.query);
        });

        const updateQuery = () => {
            if (hasChanges.value) {
                emit('update-query', tempQuery.value);
                emit('change');
            }
        };

        const cancel = () => {
            tempQuery.value = props.query;
            emit('cancel');
        };

        const preprocessCondition = (condition: any) => {
            return {
                ...condition,
                metadata: {
                    id: props.concepts[condition.concept].id,
                    uid: props.concepts[condition.concept].uid,
                    type: props.concepts[condition.concept].type,
                },
            };
        };

        const removeUnavailableConcepts = (query: any, concepts: any, isRoot = true): any => {
            if (S.has('concept', query) && S.has(query.concept, concepts)) {
                return query;
            }
            if (S.has('conditions', query)) {
                const updatedConditions = [];
                for (let c = 0; c < query.conditions.length; c++) {
                    const condition = query.conditions[c];
                    const updatedCondition = removeUnavailableConcepts(condition, concepts, false);
                    if (!R.isNil(updatedCondition)) {
                        updatedConditions.push(updatedCondition);
                    }
                }
                if (updatedConditions.length > 0 || isRoot) {
                    return {
                        ...query,
                        conditions: updatedConditions,
                    };
                }
            }

            return null;
        };

        const formatCondition = (condition: any) => {
            const operant = QueryOperant.find(condition.operant);
            const operantName = operant !== null ? operant.name : condition.operant;
            return `${condition.concept} ${operantName} ${condition.value}`;
        };

        /**
         * Validates the a query and returns a list of issues
         *
         * @param query - The query to validate
         * @returns A list of issues as strings with any problems found
         */
        const getUpdateConditionIssues = (query: { conditions: any[]; operant: string }) => {
            const issues = [];

            const operantsByConceptValue = {};
            const valueByConceptOperant = {};

            for (let c = 0; c < query.conditions.length; c++) {
                const condition = query.conditions[c];
                if (S.has('concept', condition) && S.has('operant', condition) && S.has('value', condition)) {
                    // Missing concept
                    if (R.isNil(condition.concept) || R.isEmpty(condition.concept)) {
                        issues.push('Condition is missing a concept');
                    }

                    // Missing operant
                    if (R.isNil(condition.operant) || R.isEmpty(condition.operant)) {
                        issues.push('Condition is missing an operant');
                    }

                    // Missing value
                    if (R.isNil(condition.value) || R.isEmpty(condition.value)) {
                        issues.push('Condition is missing a value');
                    }

                    // Checking case where two sibling conditions have the same concept and value
                    // but opposing operants
                    const conceptValueComboKey = `${condition.concept}_${condition.value}`;
                    if (S.has(conceptValueComboKey, operantsByConceptValue)) {
                        const operant = QueryOperant.find(condition.operant);
                        const opposite = operant?.getOpposite();
                        for (let qo = 0; qo < operantsByConceptValue[conceptValueComboKey].length; qo++) {
                            const queryOperant = operantsByConceptValue[conceptValueComboKey][qo];
                            if (opposite && opposite.key === queryOperant) {
                                issues.push(
                                    `There is another condition checking for the same concept and value as condition '${formatCondition(
                                        condition,
                                    )}'`,
                                );
                            }
                        }
                        if (operant && !S.has(operant.key, operantsByConceptValue[conceptValueComboKey])) {
                            operantsByConceptValue[conceptValueComboKey].push(operant);
                        }
                    } else {
                        operantsByConceptValue[conceptValueComboKey] = [condition.operant];
                    }

                    // Checking case where two sibling conditions have the same concept and operant
                    // and different value
                    const conceptOperantComboKey = `${condition.concept}_${condition.operant}`;
                    if (query.operant === 'AND' && S.has(conceptOperantComboKey, valueByConceptOperant)) {
                        if (!S.has(condition.value, valueByConceptOperant[conceptOperantComboKey])) {
                            issues.push(
                                `There is another condition checking with the same operant but different value as condition: '${formatCondition(
                                    condition,
                                )}'`,
                            );
                        }
                        if (condition.value && !S.has(condition.value, valueByConceptOperant[conceptOperantComboKey])) {
                            valueByConceptOperant[conceptOperantComboKey].push(condition.value);
                        }
                    } else {
                        valueByConceptOperant[conceptOperantComboKey] = [condition.value];
                    }
                }
            }
            return issues;
        };

        /**
         * Watcher is checking for any changes in the concepts (i.e When a new query is executed)
         * in order to adapt the query accordingly
         */
        watch(
            () => props.concepts,
            (concepts: any) => {
                tempQuery.value = removeUnavailableConcepts(R.clone(props.query), concepts);
                updateQuery();
            },
        );

        /**
         * Since the advanced query builder is always adapting the query (even if not visible)
         * we never realy remove it from view but it's hidden instead. In case the query builder
         * become hidden (i.e When a new query is executed) we make sure that we reset the query
         * to the one already performed
         */
        watch(
            () => props.visible,
            (visible: boolean) => {
                if (!visible) {
                    tempQuery.value = R.clone(props.query);
                    updateQuery();
                }
            },
        );

        if (R.isNil(props.query) || R.isEmpty(props.query)) {
            tempQuery.value = {
                conditions: [],
                operant: 'AND',
            };
            updateQuery();
        }

        return {
            updateQuery,
            preprocessCondition,
            getUpdateConditionIssues,
            tempQuery,
            cancel,
            hasChanges,
        };
    },
});
