

















































import { defineComponent, computed } from '@vue/composition-api';
import dayjs from 'dayjs';
import { QueryOperant } from '@/app/constants';

export default defineComponent({
    name: 'MatchedConditionsViewer',
    props: {
        query: {
            type: Object,
            required: true,
        },
        matchedConditions: {
            type: Array,
            required: true,
        },
        dateDisplayFormat: {
            type: String,
            default: 'DD MMMM YYYY',
        },
        timeDisplayFormat: {
            type: String,
            default: 'HH:mm',
        },
    },
    setup(props) {
        function trimConceptName(conceptName: string) {
            return conceptName.substring(conceptName.lastIndexOf('.') + 1, conceptName.length);
        }

        function isSatisfied(concept: any) {
            return !!props.matchedConditions.filter(
                (condition: any) => condition.conditionUid === concept.conditionUid && condition.satisfied,
            ).length;
        }

        function canBeSatisfiedBy(concept: any) {
            const unSatisfiedCondition: any = props.matchedConditions.filter(
                (condition: any) => condition.conditionUid === concept.conditionUid && !condition.satisfied,
            );
            if (unSatisfiedCondition.length === 1) {
                return unSatisfiedCondition[0].canBeSatisfiedBy.map((element: any) => element.name);
            }
            return [];
        }

        function formatValue(concept: any) {
            if (concept.metadata.type === 'datetime') {
                return dayjs(concept.value).format(`${props.dateDisplayFormat} ${props.timeDisplayFormat} UTC`);
            }
            if (concept.metadata.type === 'date') {
                const timePart = dayjs().toISOString().split('T')[1];
                return dayjs(`${concept.value}T${timePart}`).format(props.dateDisplayFormat);
            }

            if (concept.metadata.type === 'datetime') {
                const datePart = dayjs().toISOString().split('T')[0];
                return dayjs(`${datePart}T${concept.value}`).format(`${props.timeDisplayFormat} UTC`);
            }

            return concept.value;
        }

        function calculateConditions(dataQuery: any, conditions: any[]) {
            if (dataQuery && dataQuery.conditions) {
                dataQuery.conditions.forEach((element: any) => {
                    calculateConditions(element, conditions);
                });
            } else if (dataQuery && dataQuery.concept) {
                conditions.push(dataQuery);
            }
        }

        const allConditions = computed(() => {
            const conditions: any[] = [];
            calculateConditions(props.query, conditions);
            return conditions;
        });

        return { QueryOperant, allConditions, trimConceptName, formatValue, isSatisfied, canBeSatisfiedBy };
    },
});
