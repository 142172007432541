









































































import { defineComponent } from '@vue/composition-api';
import { FormBlock, TwButton } from '@/app/components';

export default defineComponent({
    name: 'ConfigureFileQuery',
    components: {
        TwButton,
        FormBlock,
    },
    model: {
        prop: 'configuration',
    },
    props: {
        configuration: {
            type: Object,
            required: true,
        },
    },
    setup(props, { emit }) {
        return { emit };
    },
});
