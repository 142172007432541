





































import { defineComponent, ref } from '@vue/composition-api';
import { DropdownMenu } from '@/app/components';

export default defineComponent({
    name: 'QueryOverview',
    components: { DropdownMenu },
    props: {
        query: {
            type: Object,
            required: true,
        },
    },
    setup(props, { emit }) {
        const options = ref([
            {
                name: 'Rename',
                action: () => {
                    emit('edit', props.query);
                },
            },
            {
                name: 'Delete',
                action: () => {
                    emit('destroy', props.query.id);
                },
            },
        ]);

        return {
            options,
        };
    },
});
