

















import { defineComponent, computed } from '@vue/composition-api';
import { JsonEditor } from '@/app/components';

export default defineComponent({
    name: 'DataQueryRetrievalPreview',
    props: {
        data: {
            type: Array,
            default: null,
        },
    },
    components: { JsonEditor },
    setup(props) {
        const dataString = computed(() => {
            return props.data.length > 0 ? JSON.stringify(props.data, null, '\t') : 'No matching records found.';
        });

        return { dataString };
    },
});
