






















































import { defineComponent, ref, computed, watch } from '@vue/composition-api';
import { useAxios } from '@vue-composable/axios';
import { OrbitSpinner } from 'epic-spinners';
import { TwButton, JsonParser, AlertBanner, FormBlock } from '@/app/components';
import { AssetsAPI } from '@/modules/asset/api';
import { JobsAPI, KafkaAPI } from '../../../data-checkin/api';

export default defineComponent({
    name: 'ConfigureStreamingQuery',
    components: {
        TwButton,
        JsonParser,
        AlertBanner,
        FormBlock,
        OrbitSpinner,
    },
    model: {
        prop: 'configuration',
        event: 'changeConfiguration',
    },
    props: {
        datasets: {
            type: Object,
            required: true,
        },
        configuration: {
            type: Object,
            required: true,
        },
    },
    setup(props, { root, emit }) {
        const { exec, loading } = useAxios(true);
        const topic = ref('');
        const sample = ref(null);
        const stepId = ref(-1);
        const isSubscribed = ref(false);

        const datasetId = computed(() => {
            return parseInt(Object.keys(props.datasets)[0], 10);
        });

        watch(
            () => datasetId.value,
            (id) => {
                exec(JobsAPI.getJobByAssetId(id)).then(async (resJob: any) => {
                    sample.value = resJob.data.sample;
                    const asset = await exec(AssetsAPI.getAsset(id));
                    topic.value = asset?.data.structure?.kafkaTopic;
                    const harvesterStepId = await exec(JobsAPI.getStepIdByNameAndAssetId(id, 'harvester'));
                    stepId.value = harvesterStepId?.data;
                    const res = await exec(KafkaAPI.getTopicConnectionDetails(topic.value, stepId.value));
                    if (res && Object.keys(res.data).length !== 0) {
                        isSubscribed.value = true;
                        emit('changeConfiguration', {
                            ...props.configuration,
                            kafkaConnectionDetails: {
                                topic: res.data.topic,
                                stepId: stepId.value,
                                group: res.data.group,
                                url: res.data.host,
                                username: res.data.username,
                                saslMechanism: res.data.algorithm,
                            },
                        });
                    }
                });
            },
            { immediate: true },
        );

        const subscribeAndNext = async () => {
            if (!isSubscribed.value) {
                exec(KafkaAPI.subscribeToTopic(topic.value, stepId.value))
                    .then((res: any) => {
                        (root as any).$toastr.s('Subscribed successfully!', 'Success');
                        console.log(res.data);
                        emit('changeConfiguration', {
                            ...props.configuration,
                            kafkaConnectionDetails: {
                                topic: res.data.topic,
                                stepId: stepId.value,
                                group: res.data.group,
                                url: res.data.host,
                                username: res.data.username,
                                password: res.data.password,
                                saslMechanism: res.data.algorithm,
                            },
                        });
                        // TODO: Save query results
                        emit('next-tab');
                    })
                    .catch(() => {
                        (root as any).$toastr.e('Subscribing to topic failed', 'Failed');
                    });
            } else {
                emit('next-tab');
            }
        };

        return {
            topic,
            sample,
            loading,
            subscribeAndNext,
            isSubscribed,
        };
    },
});
