import { AssetTypeId } from '@/modules/asset/constants';
import * as R from 'ramda';
import { QueryParameterExample, QueryParameterTypes } from './constants';

export class SearchUtils {
    static initialTextCalculator(query: any): string | null {
        let initialSearchText: string | null = null;
        if (R.isNil(query.field?.fieldId)) {
            if (!query?.text || R.trim(query.text) === '*') {
                initialSearchText = null;
            } else {
                initialSearchText = query.text;
            }
        } else {
            initialSearchText = R.join(' ', query.field.values);
        }
        return initialSearchText;
    }

    static computeApi(apiUrl: string, queryId: string, selectedQueryParameters: string[], fields: any) {
        const api: { getUrl: string; postUrl: string; body: string | null } = {
            getUrl: `${apiUrl}/${queryId}`,
            postUrl: `${apiUrl}/${queryId}`,
            body: null,
        };

        if (selectedQueryParameters.length > 0) {
            const queryParamStrings = [];
            let body: any = {};

            for (let qp = 0; qp < selectedQueryParameters.length; qp++) {
                const paramKey = selectedQueryParameters[qp];
                const paramType = fields[paramKey].queryParameterType;

                // Get Query Params
                queryParamStrings.push(
                    ...QueryParameterExample.getGetExampleParams(paramKey, fields[paramKey].type, paramType),
                );
                // Post Body
                body = {
                    ...body,
                    ...QueryParameterExample.getPostExampleParams(paramKey, fields[paramKey].type, paramType),
                };
            }
            api.getUrl += `?${queryParamStrings.join('&')}`;
            api.body = JSON.stringify(body, null, '\t');
        }

        return api;
    }

    // Helper function to flatten a concept
    static flattenConcept = (
        children: any[],
        prefix: string | null,
        datasetKey: string,
        existingParams: any,
        existingFields: any,
    ) => {
        let result: any = {};
        children.forEach((child) => {
            const key = prefix ? `${prefix}.${child.key}` : child.key;
            let hasChildren = false;
            if ('children' in child) {
                result = {
                    ...result,
                    ...SearchUtils.flattenConcept(child.children, key, datasetKey, existingParams, existingFields),
                };
                hasChildren = true;
            }
            const selected = existingFields.indexOf(key) > -1;
            const queryParameter = selected && key in existingParams;
            const queryParameterType = queryParameter ? existingParams[key].filterType : QueryParameterTypes.Value;
            result[key] = {
                indexed: hasChildren ? false : child.indexed,
                selected,
                queryParameter,
                queryParameterType,
                type: child.type,
                dataset: [datasetKey],
            };
        });
        return result;
    };

    // Helper function to flatten datasets
    static flattenConcepts = (datasets: { [x: number]: { children: any[] } }, existingQueryConfig: any) => {
        const result: any = {};
        Object.keys(datasets).forEach((datasetKey) => {
            const dataset = datasets[datasetKey];
            let existingFields = [];
            for (let i = 0; i < existingQueryConfig.datasets.length; i++) {
                const existingDataset = existingQueryConfig.datasets[i];
                let existingDatasetId = existingDataset.id;
                if (R.is(String, existingDatasetId)) {
                    existingDatasetId = parseInt(existingDatasetId, 10);
                }
                if (existingDatasetId === dataset.id) {
                    existingFields = existingDataset.fields;
                    break;
                }
            }

            if ('children' in dataset) {
                result[dataset.id] = SearchUtils.flattenConcept(
                    dataset.children,
                    null,
                    datasetKey,
                    existingQueryConfig.params,
                    existingFields,
                );
            } else {
                result[dataset.id] = {};
            }
        });
        return result;
    };

    static addCountToConcept = (model: any, counts: any) => {
        return model.children.reduce((accumulator: any[], child: any) => {
            const childId = child.uid;
            if ('children' in model && model.children.length > 0) {
                accumulator.push({
                    ...child,
                    count: childId in counts ? counts[childId] : 0,
                    children: SearchUtils.addCountToConcept(child, counts),
                });
                return accumulator;
            }
            return accumulator.push({
                ...model,
                count: childId in counts ? counts[childId] : 0,
            });
        }, []);
    };

    static createFieldPathsFromSchema = (schema: any, assetTypeId = 1): any => {
        if (assetTypeId === AssetTypeId.Dataset) return this.createFieldPathsFromSchemaDataset(schema);
        if (assetTypeId === AssetTypeId.Result) return this.createFieldPathsFromSchemaResult(schema);
        else throw new Error('Invalid asset type');
    };

    static createFieldPathsFromSchemaDataset = (schema: any, currentPath = ''): any => {
        let result = {};
        const metadata = ['_uid', '_type', 'customizedConcepts', '_index'];
        Object.keys(schema).forEach((concept: any) => {
            if (!metadata.includes(concept)) {
                result = {
                    ...result,
                    ...SearchUtils.createFieldPathsFromSchemaDataset(schema[concept], `${currentPath}${concept}.`),
                };
                // eslint-disable-next-line no-underscore-dangle
                result[`${currentPath}${concept}`] = schema[concept]._uid;
            }
        });
        return result;
    };

    static createFieldPathsFromSchemaResult = (schema: any, currentPath = ''): any => {
        let result = {};
        const metadata = ['_uid', '_type', 'customizedConcepts', '_index'];
        Object.keys(schema).forEach((concept: any) => {
            if (!metadata.includes(concept)) {
                result = {
                    ...result,
                    ...SearchUtils.createFieldPathsFromSchemaResult(schema[concept], `${currentPath}${concept}.`),
                };
                result[`${currentPath}${concept}`] = {
                    // eslint-disable-next-line no-underscore-dangle
                    type: schema[concept]._type,
                    // eslint-disable-next-line no-underscore-dangle
                    index: schema[concept]._index,
                    // eslint-disable-next-line no-underscore-dangle
                    uid: schema[concept]._uid,
                };
            }
        });
        return result;
    };

    // Iterates over datasets object and deletes their key to clear it out
    static clearObject = (obj: any) => {
        // eslint-disable-next-line no-param-reassign
        Object.keys(obj).forEach((key) => delete obj[key]);
    };
}
