import { FacetTypes } from '../constants';
import { S } from '@/app/utilities';

const facetTemplate = {
    key: null,
    label: null,
    parent: null,
    type: null,
    collapsed: true,
    data: [],
};

const facetMapping = new Map();

facetMapping.set('distribution', { label: 'Distribution', parent: null, type: null });
facetMapping.set('distribution.accessibility', {
    label: 'Accessibility',
    parent: 'distribution',
    type: FacetTypes.Multiselect,
    collapsed: false,
});
facetMapping.set('distribution.type', {
    label: 'Data Type',
    parent: 'distribution',
    type: FacetTypes.Multiselect,
    collapsed: false,
});
facetMapping.set('distribution.format', {
    label: 'Format',
    parent: 'distribution',
    type: FacetTypes.Multiselect,
    collapsed: false,
});
facetMapping.set('distribution.language', {
    label: 'Language',
    parent: 'distribution',
    type: FacetTypes.Multiselect,
    collapsed: true,
});

const specialFacet = (data: [{ value: string }], counts: any, selectedFacets: string[], conceptsUidMap: any) => {
    const specialFacets: any[] = [];
    const extraFacets = selectedFacets ? [...selectedFacets] : [];

    const sameParentCount = data.reduce((acc: any, item: any) => {
        if (conceptsUidMap[item.value].parent) {
            if (!S.has(conceptsUidMap[item.value].parent, acc)) {
                acc[conceptsUidMap[item.value].parent] = 0;
            }
            acc[conceptsUidMap[item.value].parent] += 1;
        }
        return acc;
    }, {});

    const shouldShowTooltip = Object.values(sameParentCount).filter((count: any) => count > 1).length > 0;

    data.forEach((item: any) => {
        const facet = {
            ...item,
            label: conceptsUidMap[item.value].name,
            count: item.value in counts ? counts[item.value] : 0,
        };

        if (shouldShowTooltip && conceptsUidMap[item.value].parent) {
            facet.tooltip = `domain: <span class="font-bold">${conceptsUidMap[item.value].parent}</span>`;
        }
        specialFacets.push(facet);
        if (extraFacets.includes(item.value)) {
            extraFacets.splice(extraFacets.indexOf(item.value), 1);
        }
    });

    extraFacets.forEach((facet) => {
        specialFacets.unshift({
            label: conceptsUidMap[facet].name,
            count: 0,
        });
    });

    return specialFacets;
};

// Function generates facet for generic facet
const getGenericFacet = (
    data: any,
    counts: any,
    selectedFacets: any,
    entry: { label: string; parent: string | null; type: string; collapsed?: boolean },
    key: string,
) => {
    if (key in data) {
        const extraFacets = key in selectedFacets && selectedFacets[key] ? [...selectedFacets[key]] : [];
        const facetData: any[] = [];
        data[key].forEach((item: any) => {
            facetData.push({
                name: item.value,
                count: item.value in counts ? counts[item.value] : 0,
                ...item,
            });
            if (extraFacets.includes(item.value)) {
                extraFacets.splice(extraFacets.indexOf(item.value), 1);
            }
        });
        extraFacets.forEach((facet) => {
            facetData.unshift({
                name: facet,
                count: 0,
            });
        });

        return {
            ...facetTemplate,
            key,
            data: facetData,
            ...entry,
        };
    }
};

const getFacets = (data: any, counts: any, selectedFacets: any, conceptsUidMap: any) => {
    const facets: any[] = [];

    // adding asset type first
    const assetTypeFacet = getGenericFacet(
        data,
        counts,
        selectedFacets,
        {
            label: 'Type',
            parent: 'asset',
            type: FacetTypes.Multiselect,
            collapsed: false,
        },
        'assetType',
    );
    if (assetTypeFacet) facets.push(assetTypeFacet);

    // domain facets
    facets.push({
        ...facetTemplate,
        key: 'domainsByUid',
        label: 'Domains',
        data: specialFacet(data.domainsByUid || [], counts.domainsByUid, selectedFacets.domainsByUid, conceptsUidMap),
        type: FacetTypes.Multiselect,
        collapsed: false,
    });

    // category facets
    facets.push({
        ...facetTemplate,
        key: 'categoriesByUid',
        label: 'Categories',
        data: specialFacet(
            data.categoriesByUid || [],
            counts.categoriesByUid,
            selectedFacets.categoriesByUid,
            conceptsUidMap,
        ),
        type: FacetTypes.Multiselect,
        collapsed: false,
    });

    // generic facets
    facetMapping.forEach(
        (entry: { value: string; label: string; parent: string | null; type: string }, key: string) => {
            const facet = getGenericFacet(data, counts, selectedFacets, entry, key);
            if (facet) facets.push(facet);
        },
    );
    return facets;
};

export { getFacets };
