










































import { defineComponent } from '@vue/composition-api';
import { VPopover } from 'v-tooltip';
import { v4 as uuidv4 } from 'uuid';

/**
 * Field tooltip contains the view shown when you hover over a field in the
 * advanced query builder or in the query summary
 */
export default defineComponent({
    name: 'FieldTooltip',
    components: { VPopover },
    props: {
        field: {
            type: Object,
            required: true,
        },
    },
    setup: () => {
        const id: string = uuidv4();

        return { id };
    },
});
