import { AxiosRequestConfig } from 'axios';
import { SearchQuery } from '../types';

const endpoint = '/api/search';

export default {
    all: () => ({ method: 'GET', url: `${endpoint}/queries` } as AxiosRequestConfig),
    get: (id: string) => ({ method: 'GET', url: `${endpoint}/queries/${id}` } as AxiosRequestConfig),
    create: (data: SearchQuery) => ({ method: 'POST', url: `${endpoint}/queries`, data } as AxiosRequestConfig),
    update: (id: string, data: SearchQuery) =>
        ({ method: 'PUT', url: `${endpoint}/queries/${id}`, data } as AxiosRequestConfig),
    delete: (id: string) => ({ method: 'DELETE', url: `${endpoint}/queries/${id}` } as AxiosRequestConfig),
};
