







import { defineComponent, computed } from '@vue/composition-api';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import { QueryOperant } from '@/app/constants';
import FieldTooltip from './FieldTooltip.vue';
import { S } from '@/app/utilities';

dayjs.extend(utc);

export default defineComponent({
    name: 'ConditionViewer',
    props: {
        query: {
            type: Object,
            required: true,
        },
        concepts: {
            type: Object,
            required: true,
        },
        dateDisplayFormat: {
            type: String,
            default: 'DD MMMM YYYY',
        },
        timeDisplayFormat: {
            type: String,
            default: 'HH:mm',
        },
    },
    components: { FieldTooltip },
    setup(props) {
        const concept = computed(() =>
            S.has(props.query.concept, props.concepts) ? props.concepts[props.query.concept] : null,
        );

        const value = computed(() => {
            if (concept.value.type === 'datetime') {
                return dayjs.utc(props.query.value).format(`${props.dateDisplayFormat} ${props.timeDisplayFormat} UTC`);
            }
            if (concept.value.type === 'date') {
                const timePart = dayjs().toISOString().split('T')[1];
                return dayjs.utc(`${props.query.value}T${timePart}`).format(props.dateDisplayFormat);
            }

            if (concept.value.type === 'datetime') {
                const datePart = dayjs().toISOString().split('T')[0];
                return dayjs.utc(`${datePart}T${props.query.value}`).format(`${props.timeDisplayFormat} UTC`);
            }

            return props.query.value;
        });

        return { QueryOperant, concept, value };
    },
});
